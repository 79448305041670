@mixin generate-paddings-options($n, $j:0){
    @if $j <= $n {
      @for $i from $j through $n {
        $step : $i*5;
  
        .p-t-#{$step} {
          padding-top: ($step*1px) !important;
        }
        .p-r-#{$step} {
          padding-right: ($step*1px) !important;
        }
         .p-l-#{$step} {
          padding-left: ($step*1px) !important;
        }
         .p-b-#{$step} {
          padding-bottom: ($step*1px) !important;
        }
        .padding-#{$step} {
          padding: ($step*1px) !important;
        }
      }
    }
  }
  
  @include generate-paddings-options(10);
  
  /* Generic Margin Helpers
  ------------------------------------
   */
  @mixin generate-margin-options($n, $j: 0) {
    @if $j <= $n {
      @for $i from $j through $n {
        $step : $i*5;
        .m-t-#{$step} {
          margin-top: ($step*1px) !important;
        }
        .m-r-#{$step} {
          margin-right: ($step*1px) !important;
        }
         .m-l-#{$step} {
          margin-left: ($step*1px) !important;
        }
         .m-b-#{$step} {
          margin-bottom: ($step*1px) !important;
        }
      }
    }
  }
  
  @include generate-margin-options(20);
  


@media screen and (max-width: 768px) {

  @mixin generate-paddings-options($n, $j:0){
    @if $j <= $n {
      @for $i from $j through $n {
        $step : $i*5;
  
        .p-t-#{$step} {
          padding-top: ($step*0.5px) !important;
        }
        .p-r-#{$step} {
          padding-right: ($step*0.5px) !important;
        }
         .p-l-#{$step} {
          padding-left: ($step*0.5px) !important;
        }
         .p-b-#{$step} {
          padding-bottom: ($step*0.5px) !important;
        }
        .padding-#{$step} {
          padding: ($step*0.5px) !important;
        }
      }
    }
  }
  
  @include generate-paddings-options(10);
  
  /* Generic Margin Helpers
  ------------------------------------
   */
  @mixin generate-margin-options($n, $j: 0) {
    @if $j <= $n {
      @for $i from $j through $n {
        $step : $i*5;
        .m-t-#{$step} {
          margin-top: ($step*0.5px) !important;
        }
        .m-r-#{$step} {
          margin-right: ($step*0.5px) !important;
        }
         .m-l-#{$step} {
          margin-left: ($step*0.5px) !important;
        }
         .m-b-#{$step} {
          margin-bottom: ($step*0.5px) !important;
        }
      }
    }
  }
  
  @include generate-margin-options(20);

}



@mixin generate-max-width-options($n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step : $i;
      .m-w-#{$step} {
        max-width: ($step*1%) !important;
      }
    }
  }
}

@include generate-max-width-options(100);



.m-t-1{
  margin-top: 1px !important;
}
.m-t-2{
  margin-top: 2px !important;
}
.m-t-3{
  margin-top: 3px !important;
}
.m-t-4{
  margin-top: 4px !important;
}
.m-t-6{
  margin-top: 6px !important;
}
.m-t-7{
  margin-top: 7px !important;
}
.m-t-8{
  margin-top: 8px !important;
}
.m-t-9{
  margin-top: 9px !important;
}

.m-b-1{
  margin-bottom: 1px !important;
}
.m-b-2{
  margin-bottom: 2px !important;
}
.m-b-3{
  margin-bottom: 3px !important;
}
.m-b-4{
  margin-bottom: 4px !important;
}
.m-b-6{
  margin-bottom: 6px !important;
}
.m-b-7{
  margin-bottom: 7px !important;
}
.m-b-8{
  margin-bottom: 8px !important;
}
.m-b-9{
  margin-bottom: 9px !important;
}

.m-l-1{
  margin-left: 1px !important;
}
.m-l-2{
  margin-left: 2px !important;
}
.m-l-3{
  margin-left: 3px !important;
}
.m-l-4{
  margin-left: 4px !important;
}
.m-l-6{
  margin-left: 6px !important;
}
.m-l-7{
  margin-left: 7px !important;
}
.m-l-8{
  margin-left: 8px !important;
}
.m-l-9{
  margin-left: 9px !important;
}

.m-r-1{
  margin-right: 1px !important;
}
.m-r-2{
  margin-right: 2px !important;
}
.m-r-3{
  margin-right: 3px !important;
}
.m-r-4{
  margin-right: 4px !important;
}
.m-r-6{
  margin-right: 6px !important;
}
.m-r-7{
  margin-right: 7px !important;
}
.m-r-8{
  margin-right: 8px !important;
}
.m-r-9{
  margin-right: 9px !important;
}

.p-t-1{
  padding-top: 1px !important;
}
.p-t-2{
  padding-top: 2px !important;
}
.p-t-3{
  padding-top: 3px !important;
}
.p-t-4{
  padding-top: 4px !important;
}
.p-t-6{
  padding-top: 6px !important;
}
.p-t-7{
  padding-top: 7px !important;
}
.p-t-8{
  padding-top: 8px !important;
}
.p-t-9{
  padding-top: 9px !important;
}

.p-b-1{
  padding-bottom: 1px !important;
}
.p-b-2{
  padding-bottom: 2px !important;
}
.p-b-3{
  padding-bottom: 3px !important;
}
.p-b-4{
  padding-bottom: 4px !important;
}
.p-b-6{
  padding-bottom: 6px !important;
}
.p-b-7{
  padding-bottom: 7px !important;
}
.p-b-8{
  padding-bottom: 8px !important;
}
.p-b-9{
  padding-bottom: 9px !important;
}

.p-l-1{
  padding-left: 1px !important;
}
.p-l-2{
  padding-left: 2px !important;
}
.p-l-3{
  padding-left: 3px !important;
}
.p-l-4{
  padding-left: 4px !important;
}
.p-l-6{
  padding-left: 6px !important;
}
.p-l-7{
  padding-left: 7px !important;
}
.p-l-8{
  padding-left: 8px !important;
}
.p-l-9{
  padding-left: 9px !important;
}

.p-r-1{
  padding-right: 1px !important;
}
.p-r-2{
  padding-right: 2px !important;
}
.p-r-3{
  padding-right: 3px !important;
}
.p-r-4{
  padding-right: 4px !important;
}
.p-r-6{
  padding-right: 6px !important;
}
.p-r-7{
  padding-right: 7px !important;
}
.p-r-8{
  padding-right: 8px !important;
}
.p-r-9{
  padding-right: 9px !important;
}

.relative{
  position: relative;
}

//Padding Helper Classes
@mixin generate-paddings-options-prefix($prefix,$n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step : $i*5;
      .#{$prefix}-p-t-#{$step} {
        margin-top: ($step*1px) !important;
      }
      .#{$prefix}-p-r-#{$step} {
        margin-right: ($step*1px) !important;
      }
      .#{$prefix}-p-l-#{$step} {
        margin-left: ($step*1px) !important;
      }
      .#{$prefix}-p-b-#{$step} {
        margin-bottom: ($step*1px) !important;
      }
      //@include generate-margin-options($n, ($i + 1));
    }
  }
}
//Margins Helper Classes
@mixin generate-margin-options-prefix($prefix,$n, $j: 0) {
  @if $j <= $n {
    @for $i from $j through $n {
      $step : $i*5;
      .#{$prefix}-m-t-#{$step} {
        margin-top: ($step*1px) !important;
      }
      .#{$prefix}-m-r-#{$step} {
        margin-right: ($step*1px) !important;
      }
      .#{$prefix}-m-l-#{$step} {
        margin-left: ($step*1px) !important;
      }
      .#{$prefix}-m-b-#{$step} {
        margin-bottom: ($step*1px) !important;
      }
      //@include generate-margin-options($n, ($i + 1));
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  @include generate-paddings-options-prefix('md', 3);
  @include generate-margin-options-prefix('md', 3);
}

@media (max-width: 767px) {
  //Generate Margin and Paddings.
  @include generate-paddings-options-prefix('sm', 10);
  @include generate-margin-options-prefix('sm', 10);

}

