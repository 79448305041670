@import "home";
@import "footer";
@import "build-with-us";
@import "story";
@import "landing-page";
@import "values";
@import "project";
@import "people";

::-webkit-scrollbar {
    width: 5px;
    position: absolute;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(65, 65, 65); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }