

.our-values {
    background: #1E1E1E;
    min-height: 100vh;
    width: 100vw;
    height: fit-content;
    // padding: 0px 10px;
    position: relative;
    overflow: hidden;


    a {
        text-decoration: none !important;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    .value-card {
        padding: 0px 50px;

        img {
            height: 126px;

            &.value-logo1 {
                width: 69px;
                &.halloween{
                    height: 130px;
                    width: inherit;
                }
            }

            &.value-logo2 {
                width: 80px;
            }

            &.value-logo3 {
                width: 103px;
            }
        }
    }
}



@media only screen and (min-width: 320px)and (max-width: 768px) {



    .our-values {
        padding-top: 60px;
        padding-bottom: 60px;

        h2 {
            font-size: 30px;
            line-height: normal;
        }

        .value-container-inner {
            height: auto;
        }

        .value-card {
            padding-top: 50px;

            p {
                text-align: center !important;
            }
        }
    }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .our-values {
        height: auto;

        .view-height {
            height: auto;
        }

        h2 {
            font-size: 30px;
            line-height: normal;
        }
    }

    .value-card {
        padding: 0px 150px;
        margin-bottom: 50px;

        .value-card-text {
            text-align: center !important;
        }
    }
}


@media screen and (max-width:1600px) and (min-width:960px) {
    .our-values {
        padding-top: 60px;
        height: auto;

        .view-height {
            height: auto;
        }

        .value-card {
            padding: 0px 20px;
        }

        h2 {
            font-size: 54px;
            line-height: normal;
        }
    }
}