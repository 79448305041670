.buldWithUS {
    // background-image: url("~/public/assets/images/build-with-us.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-color: #1e1e1e;

    .header {
        z-index: 10;
    }

    .placeholder-build-img {
        position: relative;
        pointer-events: none;

        img {
            position: absolute;
            width: 100%;
            height: 100vh;
        }
    }

}

.we-here {
    position: relative;
    z-index: 10;
}

.buldWithUS .container {
    height: 100vh !important;
}

.buldWithUS .container>.row {
    height: 100vh !important;
}

.reach-out-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 16px;
    gap: 12px;
    width: 253.5px;
    height: 56px;
    background: rgba(255, 255, 255, 0.14);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 49px;
    display: inline-block;
    text-transform: uppercase;
}


.discard-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 5px;
    gap: 12px;
    width: 253.5px;
    height: 56px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 49px;
    display: inline-block;
    text-transform: uppercase;
    color: #FFFFFF;
}



@media only screen and (min-width: 320px)and (max-width: 768px) {
    .buldWithUS {
        .btn-group {
            display: flex;
            flex-direction: column !important;
            gap: 20px;

            button {
                width: 100%;
            }
        }

        .header {
            justify-content: start !important;

            .buldWithUS-text {
                h1 {
                    text-align: left !important;
                }
            }
        }
    }

    .buldWithUS .placeholder-build-img img {
        // width: auto;
        width: auto;
        height: 100vh;
    }
    .build_with_us{
        &.avurudu{
            width: 300px !important;
        }
    } 

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .buldWithUS {
        .btn-group {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .header {
            justify-content: start !important;

            .buldWithUS-text {
                h1 {
                    text-align: left !important;
                }
            }
        }

        .btn-group {
            flex-direction: row !important;
        }
    }

}

// @media only screen and (max-width: 768px) {
//     .buldWithUS {
//         text-align: center;
//     }

//     .header {
//         justify-content: center !important;

//         h1 {
//             text-align: center !important;
//         }
//     }

//     .btn-group {
//         align-items: center;
//     }
// }