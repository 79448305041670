


.swiper-button-prev {
    height: 100%;
    top: 0px;
    width: 150px;
    display: flex;
    justify-content: start;
    align-items: center;
    background: linear-gradient(90deg, rgba(30, 30, 30, 1) 30%, rgba(30, 30, 30, 0) 100%);
    left: 0px;
    opacity: 0.7;

    &:after {
        color: #fff;
        position: relative;
        left: 2px;
    }
}

.swiper-button-next {
    height: 100%;
    top: 0px;
    width: 150px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: linear-gradient(270deg, rgba(30, 30, 30, 1) 30%, rgba(30, 30, 30, 0) 100%);
    right: 0px;
    opacity: 0.7;

    &:after {
        color: #fff;
        position: relative;
        right: 2px;
    }
}

.our-team {
    background: #1E1E1E;
    flex-direction: column;
    position: relative;
    overflow: hidden;


    .team-group {
        width: 100%;
        // overflow-x: visible;
        gap: 50px;
        flex-wrap: nowrap;
        height: fit-content;
        padding-bottom: 20px;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;

        /* Firefox */
        ::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
    }

    .card {
        background: transparent;
        // width: 304px;
        // width: 250px;
        width: 340px;
        height: auto;
        border: none;

        .card-heading-wrapper {
            margin-top: 4px;
            min-height: 80px;
            display: flex;
            align-items: center;

            // justify-content: center;
            .card-heading {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                text-transform: uppercase;
                margin: 0 !important;
            }
        }



        .card-para {
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            // text-transform: uppercase;
        }

        i {
            width: 20px;
            height: 20px;
            font-size: 20px;
        }

        .team-image-placeholder {
            min-height: 340px;
            height: 340px;
            overflow: hidden;
            min-width: 340px;
            background-color: #cfdfec;

            img {
                width: 340px;
                position: relative;
            }
        }

    }


}

.quote{
    z-index: 10;
    position: relative;
}

@media only screen and (min-width: 320px)and (max-width: 768px) {

    .our-team {
        padding: 0 20px;
        padding-top: 80px;

        .container {
            padding: 0px !important;
        }
    }

    .our-team .card {
        width: 353px;
    }

    .team-image-placeholder {
        img {
            width: 354px !important;
        }
    }
}