.background-body-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    pointer-events: none;

    &.right {
        background-position-x: right;
    }
}


.halloween{
    display: none;
}

.christmas{
    display: block;
}

.avurudu{
    display: block;
    &.view-height-event{
        display: flex;
        height: 100vh;
    }
}
.our-story{
    position: relative;
    .cobweb2{
        position: absolute;
        z-index: 9999;
        right: -10px;
        top: -15px;
    }
    .wreath{
        position: absolute;
        z-index: 9999;
        right: 10%;
        top: 5%;
        width:20% ;
    }
    .drummers{
        position: absolute;
        z-index: 9999;
        right: 10%;
        top: 5%;
        width:20% ;
    }
}

.our-story-section1 {
    background: #281f1e;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    a {
        text-decoration: none !important;
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    .section-2 {
        // background-image: url("~/public/assets/images/story1.png");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;

        justify-content: center;
        display: flex;
        align-items: center;

        .ourstory-placeholder-1 {
            position: absolute;
            display: none;
            img{
                width: 100%;
            }
        }

        .ourstory-video-1 {
            opacity: 1;
        }

        &.loading {
            .ourstory-placeholder-1 {
                display: block;
            }

            .ourstory-video-1 {
                opacity: 0;
            }
        }

        .ourstory-video {
            width: 100% !important;
            height: 100% !important;
            pointer-events: none;
        }

        .ourstory-video-1 {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
    }
}

.our-story-section2 {
    background: #1a1a1a;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;

    .section-1 {
        display: flex;
        align-items: center;
        position: relative;

        .ourstory-video {
            width: 100% !important;
            position: relative;
            top: 10%;
            height: 100% !important;
            opacity: 1;
        }

        .ourstory-placeholder-2 {
            position: absolute;
            /* height: auto; */
            width: 100%;
            display: none;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &.loading {
            .ourstory-video {
                opacity: 0;
            }

            .ourstory-placeholder-2 {
                display: block;
            }
        }

    }
}

.view-height {
    height: 100vh;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 320px)and (max-width: 768px) {

    .our-story-inner {
        padding: 0px 20px;
        padding-bottom: 60px;
        margin-top: 0px !important;

        .our-story-text {
            margin-bottom: 20px !important;
            margin-top: 20px;
        }

        .our-story-inner-text {
            display: none;
        }

        .overline{
            z-index: 999;
        }
    }

    .our-story .our-story-section1 .section-2 .ourstory-video {
        // height: 540px;
    }

    .our-story-section2 {
        height: 1030px !important;
        &.halloween{
            height: 1200px !important;
            .our-beginning-wrapper {
                padding: 60px 20px;
        
            }
        }

        .ourstory-video {
            height: 380px !important;
        }
    }

    .our-beginning-wrapper {
        padding: 0px 20px;
        padding-bottom: 60px;

    }


    // .our-story .our-story-section1 .our-story-inner h2{
    //     font-size: 24px;
    // }
    // .our-story .our-story-section1 .our-story-inner .overline{
    //     font-size: 12px;
    // }
    .avurudu{
        &.drummers{
            right: -15%;
            top: 5%;
            width: 60%;
            position: relative;
        }
        &#our-story-placeholder-sec-2{
            display: none;
        }
        &#our-story-placeholder-sec-1{
            display: none;
        }
        &.view-height-event{
            height: auto;
            padding-top: 100px;
        }
    }
    
}

@media screen and (max-width:960px) {
    .our-story-section1 {

        .our-story-inner {
            margin-top: 50px;
            order: 2;

            h2 {
                font-size: 30px;
                line-height: normal;
            }

            p.sm {
                text-align: justify;
            }

        }

    }

    .our-story-section1 .section-2 {
        display: flex;
        justify-content: center;
        align-items: center;

        .ourstory-video {
            width: auto;
            height: 45vh;
        }
    }

    .our-story-section2 .section-1 {
        display: flex;
        justify-content: center;
        align-items: center;

        .ourstory-video {
            width: auto;
            height: 60vh;
        }
    }


    .our-story-section2 h2 {
        font-size: 30px;
        line-height: normal;
    }
}

@media screen and (max-width:1500px) and (min-width:960px) {

    .our-story-section2,
    .our-story-section1 {
        h2 {
            font-size: 54px;
            line-height: normal;
        }
    }

    .background-body-overlay {
        display: none;
    }
}

@media screen and (max-width:1600px) {

    .background-body-overlay {
        display: none;
    }
}