.nav-bar {
    background: transparent;
    position: fixed;
    z-index: 10;
    width: 100vw;
}

.desktop-nav-bar {
    background: transparent;
    position: fixed;
    z-index: 10;
    width: 100vw;

    .ul {
        li {
            list-style-type: none;

            a {
                text-decoration: none;
            }

            a:hover {
                color: #FFFFFF;
            }
        }
    }

    .nav-bar-background {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgb(30, 30, 30);
        background: linear-gradient(180deg, rgba(30, 30, 30, 1) 0%, rgba(40, 31, 31, 0) 100%);
        z-index: -1;
        top: -92px;
        transition: all 0.4s ease-in;

        &.active {
            top: 0px;
            transition: all 0.4s ease-out;

        }
    }
}

.mobile-nav-bar {
    display: none !important;
}

.menu-link {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 16px;
    cursor: pointer;

    &.active {
        border-bottom: 2px solid #FF5A26;
    }
}

.Logo {
    img {
        height: 70px;
    }
}

.menu-link-und {
    display: inline-block;
    position: relative;
}

.menu-link-und::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FF5A26;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.menu-link-und:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}




//hamburger menu

#menuToggle {
    display: block;
    position: relative;
    top: 50px;
    left: 50px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;

    a {
        text-decoration: none;
        color: #FFFFFF;
        transition: color 0.3s ease;

        &:hover {
            color: FF9c00;
        }
    }

    input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;

        &:checked {
            ~ {
                span {
                    opacity: 1;
                    transform: rotate(45deg) translate(-2px, -1px);
                    background: #FF5A26;

                    &:nth-last-child(3) {
                        opacity: 0;
                        transform: rotate(0deg) scale(0.2, 0.2);
                    }

                    &:nth-last-child(2) {
                        transform: rotate(-45deg) translate(0, -1px);
                    }
                }

                ul {
                    transform: none;
                }
            }
        }
    }

    span {
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: #cdcdcd;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;

        &:first-child {
            transform-origin: 0% 0%;
        }

        &:nth-last-child(2) {
            transform-origin: 0% 100%;
        }
    }
}

#menu {
    position: absolute;
    width: 75vw;
    height: 120vh;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.61);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.7px);
    -webkit-backdrop-filter: blur(9.7px);
    border: 1px solid rgba(0, 0, 0, 0.3);

    li {
        padding: 10px 0;
        font-size: 22px;
    }

    .menu-link {
        padding-left: 0px;
        &.active {
            border-bottom: none;
            color: #FF5A26;
        }
    }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 320px)and (max-width: 768px) {

    .desktop-nav-bar {
        display: none !important;
    }

    .mobile-nav-bar {
        display: flex !important;
    }

    .menu-link-und::after {
        content: none;
    }

    #menuToggle {
        left: 23px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .desktop-nav-bar {
        display: none !important;
    }

    .mobile-nav-bar {
        display: flex !important;
    }

    .menu-link-und::after {
        content: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .nav-bar {
        // display: none !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...} 