@import url('https://fonts.cdnfonts.com/css/neutra-text-alt');

.slider {
  position: relative;
  width: 100vw;
  /* Full width of the viewport */
  height: 100vh;
  /* Full height of the viewport */
  overflow: hidden;
}

.slide {
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  object-fit: cover;
  /* Cover the area while maintaining aspect ratio */
  transition: none;
  /* No transition for a GIF-like effect */
}

.contactUs {
  position: absolute;
  bottom: 50px;
  width: 100vw;
  text-align: center;
  font-family: 'Neutra Text Alt', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fffafa;
  padding: 0 clamp(1rem, 2vw, 3rem);
  border-radius: 10px;
  opacity: 0;
  transition: opacity 100ms linear;

  &.active{
    opacity: 1;
  }
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}