.home-screen {
    position: fixed;
    top: 0;
    // bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    height: 100vh;
    z-index: 100;
}

.logo {
    width: 106.98px;
    height: 158px;
    margin-bottom: 64px;
}

.heading {
    font-family: 'Blinker', sans-serif;
    ;
    font-style: normal;
    font-weight: 400;
    font-size: 96px;
    line-height: 102px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 23px;
}

.hint-text {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.5;
    margin-bottom: 71px;
}

.home-button {
    padding: 16px;
    gap: 12px;
    width: 220px;
    height: 56px;
    background: rgba(255, 255, 255, 0.14);
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #FFFFFF;
    border-radius: 49px;
    text-transform: uppercase;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.home-button:hover,
.home-button:active {
    background: rgba(91, 91, 91, 0.14);
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.background-video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 100;
}


.controls-wrapper {
    position: absolute;
    top: 24px;
    right: 32px;
}

.controls-wrapper i {
    margin-left: 24px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.back-button {
    position: absolute;
    top: 24px;
    left: 32px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    padding: 10px;
    cursor: pointer;
}

.holiday-deco {
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 900px;
    z-index: 9999;
    height: 800px;
    min-width: 600px;
    pointer-events: none;

    &.inner {
        top: 165px;
        overflow: hidden;
    }

    .holloween-cobweb {
        position: absolute;
        top: 0px;
        left: 0px;

        &.spider {
            top: -327px;
        }
    }

    &.halloween {
        display: none;
    }

    &.christmas {
        min-width: 100vw;
        display: block;

        .christmas_lights {
            height: 35%;

        }

        .christmas_branch {
            right: 0px;
            position: absolute;
            height: 30%;
        }

        .christmas_stocking {
            left: 0px;
            position: absolute;
            height: 20%;
        }
    }

    &.avurudu {
        min-width: 100vw;
        display: block;

        .bo_leaves {
            right: 0px;
            top: -35px;
            position: absolute;
            height: 40%;
        }

        .swing {
            left: 0px;
            position: absolute;
            height: 60%;
        }
    }

}



@media screen and (max-width:1500px) {
    .logo {
        width: calc(106.98px * 0.8);
        height: calc(158px * 0.8);
        margin-bottom: calc(64 * 0.8);
    }

    .heading {
        font-size: calc(96px*0.8);
        line-height: calc(102px*0.8);
        margin-bottom: calc(23px*0.8);
    }

    .hint-text {
        font-size: calc(14px*0.8);
        line-height: calc(24px*0.8);
        margin-bottom: calc(71px*0.8);
    }

    .home-button {
        padding: calc(16px*0.8);
        gap: calc(12px*0.8);
        width: calc(220px*0.8);
        height: calc(56px*0.8);
        font-size: calc(14px*0.8);
        line-height: calc(22px*0.8);
    }

    .controls-wrapper i {
        font-size: calc(24px*0.8);
    }

    .back-button {
        font-size: calc(24px*0.8);
    }
}

@media screen and (max-width:660px) {
    .heading {
        font-size: calc(96px*0.5);
        line-height: calc(102px*0.5);
        margin-bottom: calc(23px*0.5);
    }

    .holiday-deco.avurudu .bo_leaves {
        height: 28%;

    }
    .holiday-deco.avurudu .swing {
        display: none;
    }
}