.carousel {
    height: 100vh;
    width: 100%;
    overflow: hidden;

    .carousel-inner {
        height: 100%;
        background: radial-gradient(41.9% 111.02% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%);

        .placeholder-project-img {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}


.carousel-item {
    height: 100%;

    .video-overlapper {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.1;
        position: absolute;
    }
}

.carousel-caption {
    left: 0;
    text-align: left;
    width: 100%;
    padding-bottom: 70px;

    .project-logo {
        width: 273.05px;
        height: 160px;
    }

    .reach-out-btn {
        padding: 16px 16px 16px 25px;
        gap: 12px;
        width: fit-content;
    }
}

.carousel-indicators {
    display: none;
}

.carousel-control-next,
.carousel-control-prev {
    align-items: end;
    bottom: 100px;
}

.carousel-control-prev {
    display: none;
}

// .carousel-control-next-icon {
//     background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
// }


.carosel-image-video-wrapper {
    overflow: hidden;

    img {
        display: none !important;
        opacity: 0;
    }

    .carosel-video {
        display: block;
        opacity: 1;
        height: 100vh !important;
        width: 100% !important;
        pointer-events: none;
        video{
            object-fit: cover;
        }
    }

    &.loading {
        .placeholder-project-img {
            display: block;
        }

        img {
            display: block !important;
            opacity: 1;
        }

        .carosel-video {
            opacity: 0;
        }
    }

    .placeholder-project-img {
        height: 100vh;
        width: auto;
        display: none;

        img {
            width: auto !important;
        }
    }


}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 320px)and (max-width: 768px) {

    .carousel-caption {
        padding: 0 20px;
    }

    .carosel-image-video-wrapper .carosel-video {
        height: 100vh !important;
        width: auto !important;


    }

    .carousel-control-next {
        bottom: 50%;
    }

    .swiper {
        .swiper-button-prev {
            background: none !important;
        }

        .swiper-button-next {
            background: none !important;
        }
    }

    .carosel-video {
        position: relative;

        // left: -500px;
        video {
            width: auto !important;
            height: 100vh !important;
            position: relative;
            left: -500px;
        }
    }

    // .carousel-item {
    //     transition: transform 2.6s ease-in-out;
    // }

    // .carousel-fade .active.carousel-item-start,
    // .carousel-fade .active.carousel-item-end {
    //     transition: opacity 0s 2.6s;
    // }

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .carosel-image-video-wrapper .carosel-video {
        height: 100vh;
        width: auto !important;
    }

    .carousel-item {
        overflow-x: hidden;
    }
}

@media screen and (max-width:1500px) and (min-width:960px) {
    .carosel-video {
        width: auto !important;
        height: 100vh;
    }
}