h1,
h2 {
    font-family: 'Blinker', sans-serif;;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
}


h1 {
    font-size: 96px;
    line-height: 102px;
    letter-spacing: 1.5px;
}

h2 {
    font-size: 64px;
    line-height: 66px;
}

p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.304px;
    margin-bottom: 8px;

    &.sm {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.304px;
    }

    &.lead {
        font-family: 'Blinker' !important;
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.00304em;
        text-transform: uppercase;
    }

    &.overline {
        font-family: 'Blinker' !important;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        &.head{
            color: #FF5A26;
        }
    }

    &.hint-text {
        font-family: 'Inter' !important;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        opacity: 0.5;
    }
}


.fs-10 {
    font-size: 10px !important;
    letter-spacing: 0.0180093em;
    line-height: 15px;
}

.fs-11 {
    font-size: 10.5px !important;
    letter-spacing: 0.0128359em;
    line-height: 16px;
}

.fs-12 {
    font-size: 12px !important;
    letter-spacing: 0.00849077em;
    line-height: 18px;
}

.fs-13 {
    font-size: 13px !important;
    letter-spacing: 0.00484144em;
    line-height: 19px;
}

.fs-14 {
    font-size: 14px !important;
    letter-spacing: 0.00177646em;
    line-height: 22px;
}

.fs-15 {
    font-size: 15px !important;
    letter-spacing: -0.000797757em;
    line-height: 23px;
}

.fs-16 {
    font-size: 16px !important;
    letter-spacing: -0.00295978em;
    line-height: 24px;
}

.fs-17 {
    font-size: 17px !important;
    letter-spacing: -0.0127756em;
    line-height: 24px;
}

.fs-18 {
    font-size: 18px !important;
    letter-spacing: -0.0143007em;
    line-height: 25px;
}

.fs-24 {
    font-size: 24px !important;
    line-height: 32px;
}

.text-opac-70 {
    opacity: 0.7;
}

.text-opac-80 {
    opacity: 0.8;
}

.text-opac-60 {
    opacity: 0.6;
}

.text-opac-90 {
    opacity: 0.9;
}

.text-opac-56 {
    opacity: 0.56;
}

.text-opac-86{
    opacity: 0.86;
} 

.text-align-end {
    text-align: end;
}

.menu {
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width:1500px) and (min-width:960px) {
    h1,h2 {
        font-size: calc(96px*0.8);
        line-height: calc(102px*0.8);
        margin-bottom: calc(23px*0.8);
    }
}

@media screen and (max-width:960px) {
    h1,h2 {
        font-size: calc(96px*0.5);
        line-height: calc(102px*0.5);
        margin-bottom: calc(23px*0.8);
    }
}