

.footer {
    background: #1E1E1E;
    padding-bottom: 48px;
    position: relative;
    overflow: hidden;
    // height: 100vh;
    .container{
        padding-top: 97px;
    }

    &.hide{
        display: none;
    }
}



.revox-sub {
    color: rgba(255, 255, 255, 0.86);
    line-height: 20px;
}

.copyright-tag {
    opacity: 0.4;
}
.social-media-icons{
    z-index: 10;

}

.rvx-logo{
    width: 10%;
}

@media only screen and (min-width: 320px)and (max-width: 768px) {

    .container {
        padding: 0px 20px;
    }

    .terms-section {
        flex-wrap: wrap;
        margin-bottom: 5px;
        justify-content: center;

        p {
            border-right: 1px solid white;
            padding-right: 20px;
        }

        p:last-child {
            border-right: none;
        }

    }

    .footer-legel-terms {
        flex-wrap: wrap;
        p {
            text-align: left;
        }
    }

    .social-media-icons {
        width: 100%;
        margin-top: 20px;
        justify-content: space-around;
    }

    .top-links {
        display: none !important;
    }

    .text-trademark {
        margin-top: 25px;
    }

    .logo-wrapper {
        display: flex;
        flex-direction: column;

        .rdwn-logo {
            margin-bottom: 25px;
        }

        .rvx-logo {
            width: 30%;
        }
    }
}